/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file
import './scss/frontend.scss';

import { Dropdown } from 'bootstrap';

import { Carousel, Fancybox } from '@fancyapps/ui';

window.fancyapps = {
  Carousel,
  Fancybox,
};

Fancybox.bind('[data-fancybox]', {
  //
});
